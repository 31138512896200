import './App.css';
import { React, useState } from 'react';
import { Configuration, OpenAIApi } from "openai";
import { InvokeCommand, LambdaClient, LogType } from "@aws-sdk/client-lambda";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import VideoList from './VideoList';

function App() {

  const [value, setValue] = useState('');
  const [answerGenerated, setAnswerGenerated] = useState(false);
  const [answer, setAnswer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [listOfSources, setListOfSources] = useState([]);

  const configuration = new Configuration({
      organization: "org-VWxqmaustRJBVxGrxdwzDCbq",
      apiKey: "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",
  });
  const openai = new OpenAIApi(configuration);
  const fantanoGifs = ['https://media3.giphy.com/media/JlpguxhuxwHiqKXoW7/giphy.gif?cid=ecf05e4786s7a6pnnorpci7f4zls9mz3syr6v19vjgsxmjye&rid=giphy.gif&ct=g',
'https://media4.giphy.com/media/lfNbkkSwzTvYIORsWU/giphy.gif?cid=790b76116a23c01d286a7be859aa8b2e0c2ac00283ed7524&rid=giphy.gif&ct=g',
"https://media3.giphy.com/media/0Gty9Mk6D1AQn7bONL/giphy.gif?cid=ecf05e4786s7a6pnnorpci7f4zls9mz3syr6v19vjgsxmjye&rid=giphy.gif&ct=g",
"https://media1.giphy.com/media/rSpbBnrSZnnJC/giphy.gif?cid=ecf05e4718ltjgggetw8t3pzrkm1rjym7w788f2h1z1o21qz&rid=giphy.gif&ct=g",
"https://media3.giphy.com/media/ihwsHf00ljMOwGhuVA/giphy.gif?cid=ecf05e47cqkgdek74c9anj41hxhcz8354bejjf7chiuf9q86&rid=giphy.gif&ct=g",
"https://media0.giphy.com/media/12QFJkNG2vlY8OpXvl/giphy.gif?cid=ecf05e4796vufeogosa0ijjmrrxtxy49z0gi2r6o7m2s4p87&rid=giphy.gif&ct=g"
] 
  
  async function fetchSources(theQuery){

    const invoke = async (funcName, payload) => {
    
      const client = new LambdaClient({
        region: "us-east-1",
        credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: "us-east-1" }),
            identityPoolId: "us-east-1:bcc5e143-a038-48bb-aef5-741819496d96",
        }),
    });


      const command = new InvokeCommand({
        FunctionName: funcName,
        Payload: JSON.stringify(payload),
        LogType: LogType.Tail,
      });

      const { Payload } = await client.send(command);
      const asciiDecoder = new TextDecoder('ascii');
      const data = asciiDecoder.decode(Payload);

      const contextObject = JSON.parse(data)['sources'];
      const sourcesObject = JSON.parse(data)['metadata'];
      setListOfSources(sourcesObject);

      const theQueryToSend = "The following are excerpts from videos posted to YouTube from American music critic Anthony Fantano. " +
      "In these execerpts, Anthony Fantano is commenting on different topics. Using the excerpts, comprehensively answer the posed question." +
      "Keep in mind, some people may refer to Anthony as 'Melon' or 'Logic' or 'The Bald Music Man', so respond the same way to these names.\n\n" +
      "Excerpt: " + contextObject['1'] + "\n" +
      "Excerpt: " + contextObject['2'] + "\n" +
      "Excerpt: " + contextObject['3'] + "\n" +
      "Excerpt: " + contextObject['4'] + "\n" +
      "Excerpt: " + contextObject['5'] + "\n" +
      "Excerpt: " + contextObject['6'] + "\n" +
      "Excerpt: " + contextObject['7'] + "\n" +
      "Excerpt: " + contextObject['8'] + "\n" +
      "Excerpt: " + contextObject['9'] + "\n" +
      "Excerpt: " + contextObject['10'] + "\n\n" +
      "Question: " + theQuery + "\n" +
      "Answer:"

      const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: theQueryToSend,
        max_tokens: 700,
        temperature: 0.70,
      });
      const responseObject = JSON.parse(response['request']['response']);
      const answer = responseObject.choices[0]['text'];

      setAnswer(answer.slice(1));
      setAnswerGenerated(true);
      setIsLoading(false);

    }
    invoke('FantanoProject', {
      'query': theQuery
    })

  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {

      setAnswerGenerated(false);
      setAnswer('');

      let query = value;
      document.getElementById('fantanoinput').value = ''
      setIsLoading(true);
      setValue('');
      fetchSources(query);
      setListOfSources([]);

    } else {
      setValue(value + event.key);
    }
  };

  if (answerGenerated){
    return (
      <div className="bg-[#effd69] min-h-screen">
        <h1 className='text-center pt-24 text-3xl'> What does Melon think...</h1>
        <div className='flex flex-row'>
          {/* <img className="block ml-auto mr-auto mt-5" src="https://media4.giphy.com/media/lfNbkkSwzTvYIORsWU/giphy.gif?cid=790b76116a23c01d286a7be859aa8b2e0c2ac00283ed7524&rid=giphy.gif&ct=g" alt="" width="250" height="250"></img> */}
        </div>
        <div className='w-96 block ml-auto mr-auto mt-5'>
          <input id="fantanoinput" onChange={e => setValue(e.target.value)} onKeyDown={handleKeyDown} className=' border-black border-2 pl-2 pr-2 pt-2 pb-2 text-md w-96' placeholder='Why does Anthony Fantano love Nav so much? ' onChar></input>
        </div>
        <div className='text-left mt-5 ml-10 mr-10 text-lg bg-white border-2 border-black p-5'>
          <h2>{answer}</h2>
        </div>
<div className='text-center mt-5 ml-10 mr-10 text-lg pb-5'>Sorry! Ask Melon no longer works :( </div>
        <div className='text-center mt-5 ml-10 mr-10 text-lg pb-5'>
          <h2 className=''><b>Sources</b></h2>
          <VideoList videos={listOfSources} />
        </div>
      </div>
    );
  }
  else{
    if (! isLoading){
      return (
        <div className="bg-[#effd69] min-h-screen">
          <h1 className='text-center pt-24 text-3xl'> What does Melon think...</h1>
          <div className='flex flex-row'>
            {/* <img className="block ml-auto mr-auto mt-5" src="https://media4.giphy.com/media/lfNbkkSwzTvYIORsWU/giphy.gif?cid=790b76116a23c01d286a7be859aa8b2e0c2ac00283ed7524&rid=giphy.gif&ct=g" alt="" width="250" height="250"></img> */}
          </div>
          <div className='w-96 block ml-auto mr-auto mt-5'>
            <input id="fantanoinput" onChange={e => setValue(e.target.value)} onKeyDown={handleKeyDown} className='pl-2 pr-2 pt-2 pb-2 text-md w-96' placeholder='Why does Anthony Fantano love Nav so much? ' onChar></input>
          </div>
          <div className='text-center mt-5 ml-10 mr-10 text-lg pb-5'>Sorry! Ask Melon no longer works :( </div>
        </div>
      );
    }
    else{
      return (
      <div className="bg-[#effd69] min-h-screen">
        <h1 className='text-center pt-24 text-3xl'> What does Melon think...</h1>
        <div className='flex flex-row'>
          <img className="block ml-auto mr-auto mt-5" src={fantanoGifs[Math.floor(Math.random() * 5)]} alt="" width="250" height="250"></img>
        </div>
        <div className='w-96 block ml-auto mr-auto mt-5'>
          <input id="fantanoinput" onChange={e => setValue(e.target.value)} onKeyDown={handleKeyDown} className='pl-2 pr-2 pt-2 pb-2 text-md w-96' placeholder='Why does Anthony Fantano love Nav so much? ' onChar></input>
        </div>
<div className='text-center mt-5 ml-10 mr-10 text-lg pb-5'>Sorry! Ask Melon no longer works :( </div>
      </div>
    );
    }
  }
};

export default App;
