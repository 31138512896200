import React from 'react';

const Video = ({ channel, title, url }) => (
  <div className='bg-black border-white text-[#effd69] pl-2 pr-2 pt-1 pb-1 mt-2'>
    <a href={url}>
        <h2>{channel} - {title}</h2>
    </a>
  </div>
);

const VideoList = ({ videos }) => (
  <div className=''>
    {videos.map(video => (
      <Video key={video.url} {...video} />
    ))}
  </div>
);

export default VideoList;